<template>
  <div>
    <div class="search icon-input" v-if="search_box">
      <span class="search-icon glyphicon glyphicon-search"></span>
      <input
        class="form-control"
        type="text"
        v-model="search"
        placeholder="输入姓名/公司/产品搜索"
      />
      <span @click="clear" class="clear-icon glyphicon glyphicon-remove"></span>
    </div>
    <card v-for="m in members" :m="m" :key="m.id"></card>
    <infinite-loading @infinite="onInfinite" :identifier="infiniteId"></infinite-loading>
  </div>
</template>

<script>
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Card from "./card.vue";
import InfiniteLoading from "vue-infinite-loading";
import {api} from './api'
export default {
  props: {
    search_box: {
      type: Boolean,
    },
    _search: {
      type: String,
      default: "",
    },
  },

  components: {
    card: Card,
    InfiniteLoading,
  },

  data() {
    return {
      members: [],
      page: 0,
      search: this._search,
      infiniteId: +new Date(),
    };
  },

  watch: {
    search(val, oldVal) {
      return this.reset();
    },
  },
  methods: {
    reset() {
      this.page = 0;
      this.members = [];
      return (this.infiniteId += 1);
    },

    clear() {
      this.search = "";
      return this.reset();
    },

    onInfinite($state) {
      this.page += 1;
      api.get("members", { params: { q: this.search, page: this.page } })
      .then(({data}) => {
          if (data.length === 0) {
            return $state.complete();
          } else {
            this.members = this.members.concat(data);
            return $state.loaded();
          }
      })
    },
  },
};
</script>
