import axios from "axios";
import { queryStringify } from "../utils";


const prefixUrl = '/phonebook'


const api = axios.create({
    baseURL: prefixUrl,
    headers: {
        Accept: 'application/json',
    },
    paramsSerializer: queryStringify
});


export { prefixUrl, api };