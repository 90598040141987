import { createApp } from 'vue'

import PhonebookIndex from "./list.vue"

window.onload = () => {
  if (document.getElementById("phonebook-app")) {
    const app = createApp({});
    app.component("phonebook-index", PhonebookIndex);
    app.mount("#phonebook-app");
  }
};
